@import '../../styles/variables.scss';

.projectCard {
  display: block !important;
  .image-container {
    height: 175px;
    background-size: cover;
    background-position: center;
    border-radius: 13px;
    box-shadow: 0 0 0 1px var(--neutralQuaternary);
  }

  .heading,
  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0 4px;
  }

  .heading {
    -webkit-line-clamp: 1;
    height: 24px;
    cursor: pointer;
    color: var(--themePrimary);
    span {
      color: var(--themePrimary);
    }
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--themeDarker);
      color: var(--themeDarker);
      span {
        color: var(--themeDarker);
      }
    }
    &:after {
      content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAbUlEQVQYV2NkwAGMjY3/I0sxogucPXuWEabA1NQ04d+/f/NBfLBCZEl0RUxMTIkgxVgVwkwCKTp9+vQCkGEYCtEVwWxAUWhhYaHw+/fv+zCTMDyD7EaQ4hMnTjyAKYL5AadnKFOIK9Bh4iCnAQCQ91xr2Cv0sgAAAABJRU5ErkJggg==);
      margin: 0 3px 0 5px;
    }
  }

  .isInverted {
    &:after {
      filter: invert(1);
    }
  }

  .description {
    -webkit-line-clamp: 4;
    height: 94px;
  }

  .footerButton {
    margin: 0;
    border: none;
  }
}

.projects {
  .ms-Grid {
    padding: 0;
  }

  .emptyState {
    height: 350px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #808080;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    background-image: url('../../assets/img/inProgress.svg'),
      url('../../assets/img/bg2.png');
    margin: 0 8px;
    border: 8px solid #808080;
    border-top-width: 16px;
  }
  .emptyStateText {
    background-color: #808080;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin: 0 8px;
    border: 8px solid #808080;
    margin-bottom: 20px;
    text-align: center;
    span {
      color: white;
      text-shadow: rgb(46, 46, 46) 1px 1px !important;
    }
    .asLink {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.panel {
  .ms-Panel-content {
    color: var(--neutralPrimary);
    overflow: hidden;
    a {
      color: var(--themePrimary);
    }
    p {
      font-weight: 600;
    }
  }
  .panel-image {
    max-height: 250px;
    border-radius: 16px;
  }
}

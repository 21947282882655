@import '../../styles/variables.scss';

.timeline-container {
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 10px;
  }

  .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 18px;

    &::after {
      content: '';
      position: absolute;
      width: 4px;
      top: 0;
      bottom: 0;
      border-radius: $border-radius;
    }

    .description-container {
      padding: 8px 0 8px 22px;
      position: relative;
    }

    .description-container::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: -8px;
      border: 4px solid #ff9f55;
      top: 28px;
      border-radius: 100%;
      z-index: 1;
    }

    .right::before {
      content: ' ';
      height: 0;
      position: absolute;
      top: 28px;
      width: 0;
      z-index: 1;
      left: 13px;
      border-width: 10px 10px 10px 0 !important;
    }

    .content {
      padding: 8px;
      border-radius: $border-radius;
    }
    h4 {
      margin: 0;
    }
    p {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.timeline-card {
  display: block !important;
  padding-left: 6px !important;
  h3 {
    font-family: pKalam;
    font-size: 24px;
    font-weight: 100 !important;
    margin: 0 8px;
  }
  .custom-button-container {
    margin-top: -30px;
    margin-bottom: -4px;
    .custom-button {
      z-index: 1;
      margin-left: 10px;
    }
  }
}

.trim-height {
  max-height: 279px;
  overflow: hidden;
  @media (max-width: 1199px) {
    height: 259px;
  }
}

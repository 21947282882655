/*Keep theme.ts and this file in sync*/
/*Class name is one of the values of ThemeModes enum in theme.ts*/
.light {
  --fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif";
  --themePrimary: #0078d4;
  --themeLighterAlt: #eff6fc;
  --themeLighter: #deecf9;
  --themeLight: #c7e0f4;
  --themeTertiary: #71afe5;
  --themeSecondary: #2b88d8;
  --themeDarkAlt: #106ebe;
  --themeDark: #005a9e;
  --themeDarker: #004578;
  --neutralLighterAlt: #e0e1e7;
  --neutralLighter: #dcdde3;
  --neutralLight: #d3d4da;
  --neutralQuaternaryAlt: #c5c5cb;
  --neutralQuaternary: #d1d9e6;
  --neutralTertiaryAlt: #b4b5ba;
  --neutralTertiary: #c2c2c2;
  --neutralSecondary: #858585;
  --neutralPrimaryAlt: #4b4b4b;
  --neutralPrimary: #333333;
  --neutralDark: #272727;
  --black: #1d1d1d;
  --white: #e6e7ee;
  --cardShadowHovered: #b8b9be;
}

@import '../../styles/commonStyles.scss';

.profile {
  display: block !important;
  padding-top: 60px !important;
  margin-top: 40px;
  animation-duration: 0s !important;
  animation-delay: 0s !important;
  .name {
    @extend .decorated-text;
    line-height: 14px;
    font-size: 32px;
  }
  .image-container {
    width: 100px;
    margin-top: -104px;
    float: left;
    border-radius: 16px;
    padding: 8px;
    background: linear-gradient(to bottom, var(--themeTertiary), var(--white));
    .short,
    .detailed {
      margin-left: -20px;
      margin-right: 20px;
    }
    .detailed {
      height: 116px;
      margin-top: -16px;
    }
  }
  .plutonic {
    position: absolute;
    right: 0;
    top: -44px;
    span {
      &::before {
        background-color: transparent;
      }
      span {
        text-decoration: underline;
      }
    }
  }
}

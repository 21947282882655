@import '~office-ui-fabric-core/dist/sass/References';
@import './app/styles/border.scss';
@import './app/styles/variables.scss';
@import './app/styles/generics.scss';
@import './app/components/theme/light.scss';
@import './app/components/theme/dark.scss';

.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.card {
  border-radius: $border-radius;
}

.card {
  @include ms-motion-slideUpIn;
  padding: 16px;
  margin-bottom: 20px;
  display: flex;
}

.ms-Pivot {
  border-radius: 999px !important;
  * {
    height: 36px;
    border-radius: 999px !important;
    font-size: 18px;
    font-family: pKalam;
    transition-duration: 0ms !important;
    font-weight: 500 !important;
  }
  button {
    padding: 0;
  }
  .ms-Pivot-link:hover,
  .is-selected {
    background-color: transparent;
  }
  .ms-Pivot-link:hover {
    color: var(--neutralPrimary);
  }
  .ms-Pivot-link {
    @include single-motion-bottom(2px, var(--themePrimary));
    &::before {
      display: none;
    }
  }
  .is-selected {
    * {
      color: var(--themePrimary);
    }
  }
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
  margin: 8px;
  .ms-Button {
    width: 100%;
  }
}

.vertical {
  writing-mode: vertical-rl;
  position: fixed;
  transform: rotate(180deg) !important;
  font-size: 15px;
}

.vl {
  top: 175px;
  left: 0;
}

.vr {
  top: 200px;
  right: 0;
}

.sub-pivot {
  .ms-Pivot {
    margin: 0 0 16px;
  }
  .ms-Pivot-link:last-child {
    margin-right: 4px;
  }
}

.ms-Panel-main {
  margin: 16px;
  border-radius: $border-radius;
  color: var(--black);
  @media (max-width: 480px) {
    width: 90%;
  }
}

.ms-Button {
  border-radius: $border-radius;
}

.custom-button-container {
  margin-top: 12px;
  justify-content: center;
  .custom-button {
    user-select: none;
    cursor: pointer;
    position: relative;
    padding: 8px;
    border-radius: $border-radius;
    display: flex;
    align-items: flex-end;
    @include single-motion-bottom(2px, var(--themePrimary));
    .custom-button-icon {
      margin-right: 4px;
    }
  }
}

.disabled-button {
  cursor: not-allowed !important;
  * {
    cursor: not-allowed !important;
  }
}

.my-masonry-grid {
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column {
  margin: auto;
}

iframe {
  width: 100%;
  height: 300px;
  border-radius: $border-radius !important;
}

.iframe-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.skill-cloud {
  height: 316px;
  background-image: url('./app/assets/img/sking.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1200px-1) {
    height: 296px;
  }
}

.f-90 {
  animation: flip 0.5s !important;
}

@keyframes flip {
  0% {
    transform: translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
  50% {
    transform: translate3d(0, -48px, 0) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--neutralTertiary);
  border: 2px solid var(--white);
  border-radius: 24px;
  &:active {
    background: var(--neutralSecondary);
  }
}

.contribution-grid-con {
  min-height: 150px;
  .contribution-grid {
    object-fit: cover;
  }
}
.header {
  .ms-Pivot-link:last-child {
    background-image: linear-gradient(
      to bottom right,
      var(--themeTertiary),
      var(--white)
    );
    margin-right: 5px;
    &[class*='linkIsSelected'] {
      box-shadow: inset 0 0 0px 2px var(--themePrimary) !important;
    }
    * {
      font-family: 'FabricMDL2Icons-8';
      font-size: 18px;
    }
    &::before {
      display: none;
    }
  }
}

.w-100 {
  width: 100%;
}

.m-2 {
  margin: 8px;
}

.m-l-1 {
  margin-left: 4px;
}

.m-l-2 {
  margin-left: 8px;
}

.m-t-2 {
  margin-top: 8px !important;
}

.m-t-3 {
  margin-top: 12px !important;
}

.m-t-4 {
  margin-top: 16px;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-5 {
  margin-bottom: 20px !important;
}

.d-block {
  display: block !important;
}

.d-hidden {
  display: hidden !important;
  visibility: hidden !important;
}

.p-1 {
  padding: 4px !important;
}

.p-2 {
  padding: 8px !important;
}

.p-l-1 {
  padding-left: 4px;
}

.animation-none {
  animation: none !important;
}

.bg-white {
  background-color: white;
  border: 2px solid gray;
}

.ms-Grid-col {
  padding-left: 10px;
  padding-right: 10px;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.p-r-1 {
  padding-right: 4px;
}

.lg-p-r-0 {
  @media (max-width: 1024px-1) {
    padding-right: 0;
  }
}

.lg-p-l-0 {
  @media (max-width: 1024px-1) {
    padding-left: 0;
  }
}

.a-d1 {
  animation-delay: 0.05s !important;
}

.a-d2 {
  animation-delay: 0.1s !important;
}

.a-d3 {
  animation-delay: 0.15s !important;
}

.a-d4 {
  animation-delay: 0.2s !important;
}

.a-d5 {
  animation-delay: 0.25s !important;
}

.a-d6 {
  animation-delay: 0.3s !important;
}

.text-right {
  text-align: right;
}

.view-pens {
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: 2px dotted var(--neutralTertiary);
}

.p-5px {
  padding: 5px !important;
}

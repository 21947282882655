.icon-container {
  justify-content: center;
  padding: 0 !important;
  .icon {
    align-self: center;
    height: 140px;
    cursor: pointer;
  }
}

.github-icon {
  .icon {
    height: 136px;
  }
}

.codepen-icon {
  .icon {
    height: 110px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}